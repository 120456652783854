import io from 'socket.io-client';
import { getAccessToken } from '../http/auth/auth';
import { SOCKETS } from '../../constants';

let GLOBAL_SOCKET;

const BASE_EVENTS = {
    CONNECT: 'connect',
    RECONNECT: 'reconnect',
    DISCONNECT: 'disconnect',
    RESPONSE: 'response',
    CONNECTION_ERROR: 'connect_error',
    CONNECTION_END: 'end',
    AUTHENTICATION_ERROR: 'authentication_error',
};

function getBaseSocketOptions() {
    return {
        path: SOCKETS.path,
        transportOptions: {
            polling: {
                extraHeaders: {
                    'X-Access-Token': getAccessToken(),
                },
            },
        },
    };
}

export function getBaseEvents() {
    return BASE_EVENTS;
}

export default function getGlobalSocket() {
    if (GLOBAL_SOCKET !== undefined) {
        if (GLOBAL_SOCKET.disconnected === true) {
            GLOBAL_SOCKET.open();
        }
        return GLOBAL_SOCKET;
    }
    const address = `${SOCKETS.baseURL}${SOCKETS.baseNameSpace}`;
    GLOBAL_SOCKET = io.connect(address, getBaseSocketOptions());
    return GLOBAL_SOCKET;
}

export function removeSocket() {
    GLOBAL_SOCKET = null;
}
