let RESPONSE_QUEUE = {};

export function queue(id, callback) {
    RESPONSE_QUEUE[id] = callback;
}

export function get(res) {
    if (res.data.eventId) {
        const callback = RESPONSE_QUEUE[res.data.eventId];
        if (callback) {
            callback(res);
            return;
        }
        const msg = `Could not find response handler for event: ${res.data.eventId}`;
        const error = new Error(msg);
        throw error;
    }
}

export function clear({ key, prefix }) {
    if (key) {
        delete RESPONSE_QUEUE[key];
        return;
    }
    if (prefix) {
        Object.keys(RESPONSE_QUEUE)
            .filter((aKey) => aKey.includes(prefix))
            .forEach((prefixKey) => {
                delete RESPONSE_QUEUE[prefixKey];
            });
        return;
    }
    RESPONSE_QUEUE = {};
}
