import {
    signIn,
    signInWithGoogle,
    authorizeGoogle,
    getUser,
    logout,
    signUp,
} from '../../../utils/api/http/auth/auth';
import { syncCourses } from '../../../utils/api/http/courses/courses';
import { generateMutations } from '../../utils';

const defaultState = {
    user: {},
    sessionStatus: null,
};

const getters = {
    user: (state) => state.user,
};

const actions = {
    async signInWithGoogle() {
        await signInWithGoogle();
    },

    async signIn({ commit }, { email, password }) {
        const user = await signIn({ email, password });
        commit('setUser', user);
        return user;
    },

    async signUp(ctx, { email, password, name }) {
        await signUp({ email, password, name });
    },

    async authorizeGoogle({ commit }, query) {
        const result = await authorizeGoogle(query);
        commit('setUser', result);
        return result;
    },

    getUser({ commit, state }) {
        const user = getUser();
        if (!state.user.name) {
            commit('setUser', user);
        }
        return user;
    },

    logout({ commit }) {
        logout();
        commit('setUser', {});
    },

    sync() {
        return syncCourses();
    },
};

const mutations = generateMutations(defaultState);

export default {
    state: defaultState,
    namespaced: true,
    getters,
    actions,
    mutations,
};
