import invoke from '../../invoke';

export async function createCourse(course) {
    const data = {
        ...course,
        description: {
            type: 'text',
            content: course.description,
        },
        runtimes: [],
        services: [],
        locales: [],
        author: {},
        lessons: [],
        projectMode: true,
        projectFiles: [],
    };
    const result = await invoke('POST', '/courses', data);
    return result;
}

export async function fetchCourses({ page = 0, limit = 5 }) {
    const result = await invoke('GET', `/courses?page=${page}&limit=${limit}`);
    return result;
}

export async function fetchCourse(courseId) {
    const result = await invoke('GET', `/courses/${courseId}`);
    return result;
}

export async function modifyCourse(courseId, updates) {
    const data = {
        ...updates,
    };
    if (updates.description) {
        data.description = {
            type: 'text',
            content: updates.description,
        };
    }
    const result = await invoke('PATCH', `/courses/${courseId}`, data);
    return result;
}

export async function deleteCourse(courseId) {
    const result = await invoke('DELETE', `/courses/${courseId}`);
    return result;
}

export async function syncCourses() {
    const result = await invoke('POST', '/courses/sync');
    return result;
}

export async function uploadCourseFiles(input, opts) {
    const { courseId = null, runtime = null } = opts;
    const formdata = new FormData();
    const files = [].slice.call(input.files);

    if (!runtime) {
        throw new Error('language required');
    }

    if (!Array.isArray(files) || files.length < 1) {
        throw new Error('no files to upload');
    }

    files.forEach((file) => {
        formdata.append(file.webkitRelativePath || file.name, file);
    });

    const result = await invoke(
        'POST',
        `/courses/${courseId}/projectFiles/${runtime}`,
        formdata,
        { json: false },
    );
    return result;
}
