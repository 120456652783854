function emptyFields(objc = {}) {
    const keys = Object.keys(objc);
    const values = Object.values(objc);

    const empty = values
        .map((value, index) => (!value ? keys[index] : null))
        .filter((value) => value !== null)
        .map((value) => value.toLowerCase());

    return empty;
}

function email(str) {
    const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

    if (!str.match(emailRegex)) {
        const message = 'email is invalid';
        return { error: true, message };
    }
    return { error: false, message: '' };
}

export default {
    email,
    emptyFields,
};
