const {
    baseURL,
    oauth,
    domain,
    sockets,
} = YODA_CMS; // eslint-disable-line no-undef

export const BASE_URL = baseURL;
export const ACCESS_TOKEN = 'atok';
export const USER = 'dw';
export const TIMEOUT_STATUS = 408;
export const DOMAIN = domain;
export const SOCKETS = sockets;
export const SAVE_INTERVAL = 30000;
export const OAUTH_GOOGLE = oauth.google;
