import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import Signin from './views/auth/Signin.vue';

import {
    getUser,
    getAccessToken,
    logout,
} from './utils/api/http/auth/auth';
import { EventBus } from './utils';

Vue.use(Router);
NProgress.configure({ showSpinner: false });

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'signin',
            component: Signin,
            meta: {},
        },
        {
            path: '/auth/callback',
            name: 'authcallback',
            props: true,
            meta: {},
            component: () => import('@/views/auth/GoogleAuthCallback.vue'),
        },
        {
            path: '/home',
            name: 'home',
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/home/Home.vue'),
        },
        {
            path: '/docs/dashboard',
            name: 'lessonsDashboard',
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/Docs.vue'),
        },
        {
            path: '/users',
            name: 'createUser',
            meta: {
                requiresAuth: true,
                adminAuth: true,
            },
            component: () => import('@/views/users/Index.vue'),
        },
        {
            path: '/courses/create',
            name: 'createCourse',
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/courses/CreateCourse.vue'),
        },
        {
            path: '/courses/dashboard',
            name: 'courseDashboard',
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/courses/CourseDashboard.vue'),
        },
        {
            path: '/courses/edit/:courseId',
            name: 'courseEditView',
            props: true,
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/courses/ModifyCourse.vue'),
        },
        {
            path: '/courses/:courseId/lessons/dashboard',
            name: 'courseLessonsDashboard',
            props: true,
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/courses/lessons/CourseLessonsDashboard.vue'),
        },
        {
            path: '/courses/coderoom/:courseId',
            name: 'codeRoom',
            props: true,
            meta: {
                requiresAuth: true,
                normalAuth: true,
            },
            component: () => import('@/views/courses/CodeRoom.vue'),
        },
    ],
});

EventBus.$on('expired-token', () => {
    logout();
    router.push({ path: '/' });
});

function authenticate(to, from, next) {
    const accessToken = getAccessToken();
    const user = getUser();

    const signinRoute = '/';
    const signupRoute = '/signup';
    const currentPath = to.path.toLowerCase();

    const isAuthenticated = user && accessToken;
    const isNotAuthenticated = !isAuthenticated;

    const hasCredentials = isAuthenticated
    && (currentPath === signinRoute || currentPath === signupRoute);

    const isAdmin = user && user.role.toLowerCase() === 'admin';
    const isNormal = user && user.role.toLowerCase() === 'normal';

    if (to.meta.requiresAuth) {
        const requiresNormal = to.meta.normalAuth === true;
        const requiresAdmin = to.meta.adminAuth === true;
        if (isNotAuthenticated) {
            next('/');
            return;
        }

        if (requiresAdmin && isAdmin) {
            next();
            return;
        }

        if (requiresNormal && (isAdmin || isNormal)) {
            next();
            return;
        }
        next('/');
        return;
    }

    if (hasCredentials) {
        next('/home');
        return;
    }
    next();
}

router.beforeEach((to, from, next) => {
    authenticate(to, from, next);
});

export default router;
