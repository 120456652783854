import invoke from '../../invoke';

export async function createLesson(data) {
    const lesson = {
        content: [{
            step: 1,
            content: '',
            validations: {},
        }],
        type: 'context',
        layoutComponents: [],
        attributes: [],
        files: [],
        completed: false,
        ...data,
        description: {
            type: 'text',
            content: data.description,
        },
    };

    const result = await invoke('POST', '/lessons', lesson);
    return result;
}

export async function modifyLesson(lessonId, updates) {
    const payload = {
        ...updates,
        description: {
            type: 'text',
            content: updates.description,
        },
    };
    const result = await invoke('PATCH', `/lessons/${lessonId}`, payload);
    return result;
}
export async function fetchLesson(lessonId) {
    const result = await invoke('GET', `/lessons/${lessonId}`);
    return result;
}

export async function fetchLessons() {
    const result = await invoke('GET', '/lessons/');
    return result;
}
export async function deleteLesson(lessonId) {
    const result = await invoke('DELETE', `/lessons/${lessonId}`);
    return result;
}
