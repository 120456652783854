import Vue from 'vue';
import Vuex from 'vuex';

import courses from './modules/courses/courses';
import courseLessons from './modules/courses/lessons/lessons';
import lessons from './modules/lessons/lessons';
import content from './modules/content/content';

import auth from './modules/auth/auth';
import users from './modules/users/users';
import runtimes from './modules/runtimes/runtimes';
import sockets from './modules/sockets';

import { EventBus } from '../utils';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
    modules: {
        courses,
        courseLessons,
        lessons,
        auth,
        users,
        runtimes,
        sockets,
        content,
    },
    strict: debug,
});

EventBus.$on('expired-token', () => {
    store.commit('auth/setSessionStatus', 'expired');
});

export default store;
