import { searchUsers } from '../../../utils/api/http/users';
import { generateMutations } from '../../utils';

const defaultState = {
    users: [],
};

const actions = {
    async getUsers({ commit }, { email }) {
        const newUsers = await searchUsers({ email });
        const newState = newUsers;
        commit('setUsers', newState);
    },
};

const mutations = generateMutations(defaultState);
export default {
    state: defaultState,
    namespaced: true,
    actions,
    mutations,
};
