import Lockr from 'lockr';

import { decodeToken } from '../../..';
import invoke from '../../invoke';
import validate from '../../../validation';
import {
    ACCESS_TOKEN,
    USER,
    OAUTH_GOOGLE,
    BASE_URL,
    DOMAIN,
} from '../../../constants';

export function validateUserCredentials(userCredentials) {
    const userDetails = {
        email: userCredentials.email,
        firstName: userCredentials.firstname,
        lastName: userCredentials.lastname,
        password: userCredentials.password,
        phoneNumber: userCredentials.phonenumber,
    };

    const emptyFields = validate.emptyFields(userDetails);

    if (emptyFields.length > 0) {
        const message = `${emptyFields.join(' ')} must be filled`;
        return { error: true, message, credentials: {} };
    }

    const emailValidationResult = validate.email(userDetails.email);
    if (emailValidationResult.error) {
        return { ...emailValidationResult, credentials: {} };
    }

    return {
        error: false,
        message: '',
        credentials: userDetails,
    };
}

async function doAuth(type, credentials) {
    const isSignin = type.toLowerCase() === 'signin';
    const url = ['/auth/', isSignin ? 'signin' : 'signup'].join('');
    const result = await invoke(
        'POST',
        url,
        credentials,
    );

    if (result) {
        const userInfo = decodeToken(result.accessToken);
        Lockr.set(ACCESS_TOKEN, result.accessToken);
        Lockr.set(USER, userInfo);
        return userInfo;
    }

    return null;
}

export async function signUp(credentials) {
    const userInfo = await doAuth('signup', credentials);
    return userInfo;
}

export async function signIn(credentials) {
    const userInfo = await doAuth('signin', credentials);
    return userInfo;
}

export async function signInWithGoogle() {
    const {
        authorizationUrl,
        scope,
        clientId,
    } = OAUTH_GOOGLE;

    let url = `${authorizationUrl}?`;
    url += `client_id=${clientId}`;
    url += `&scope=${scope}`;
    url += `&redirect_uri=${BASE_URL.client}/auth/callback`;
    url += '&response_type=code';
    url += `&hd=${DOMAIN}`;

    window.location = url;
}

export async function authorizeGoogle(query) {
    const {
        code,
    } = query;

    const requestData = {
        service: 'google',
        authorizationCode: code,
        signupIfNotFound: true,
    };

    const response = await doAuth('signin', requestData);
    return response;
}

export function logout() {
    Lockr.rm(USER);
    Lockr.rm(ACCESS_TOKEN);
}

export function getUser() {
    return Lockr.get(USER);
}

export function getAccessToken() {
    return Lockr.get(ACCESS_TOKEN);
}
