import invoke from '../../../invoke';

export async function createCourseLesson(courseId, data) {
    const lesson = {
        content: [{
            step: 1,
            content: '',
            validations: {},
        }],
        type: 'context',
        layoutComponents: [],
        attributes: [],
        files: [],
        completed: false,
        ...data,
        description: {
            type: 'text',
            content: data.description,
        },

    };
    const result = await invoke('POST', `/courses/${courseId}/lessons`, lesson);
    return result;
}

export async function modifyCourseLesson({ courseId, lessonId, updates }) {
    const payload = {
        ...updates,
        description: {
            type: 'text',
            content: updates.description,
        },
    };

    const result = await invoke(
        'PATCH',
        `/courses/${courseId}/lessons/${lessonId}`,
        payload,
    );
    return result;
}

export async function fetchCourseLesson(courseId, lessonId) {
    const result = await invoke(
        'GET',
        `/courses/${courseId}/lessons/${lessonId}`,
    );
    return result;
}

export async function fetchCourseLessons(courseId) {
    const result = await invoke(
        'GET',
        `/courses/${courseId}/lessons`,
    );
    return result;
}

export async function deleteCourseLesson(courseId, lessonId) {
    const result = await invoke(
        'DELETE',
        `/courses/${courseId}/lessons/${lessonId}`,
    );
    return result;
}
