import Lockr from 'lockr';
import { EventBus } from '..';

import {
    USER,
    BASE_URL,
    ACCESS_TOKEN,
} from '../constants';

async function invoke(
    method,
    path,
    data = undefined,
    options = {
        auth: true,
        timeout: 50000,
        headers: {},
        json: true,
    },
) {
    const headers = { ...options.headers };
    const accessToken = Lockr.get(ACCESS_TOKEN);

    if (accessToken) {
        headers.Authorization = `Bearer ${Lockr.get(ACCESS_TOKEN)}`;
    }

    const opts = {
        method,
        mode: 'cors',
        headers,
    };

    if (data && options.json) {
        opts.headers['Content-Type'] = 'application/json';
        opts.body = JSON.stringify(data);
    }

    if (data && options.json === false) {
        opts.body = data;
    }

    let res = {};

    const url = `${BASE_URL.api}${path}`
        .replace(/\+/, '%2B')
        .replace(/ /, '%20');

    res = await fetch(url, opts);

    if (!(res.status >= 200 && res.status < 300)) {
        if (res.status === 401) {
            Lockr.rm(ACCESS_TOKEN);
            Lockr.rm(USER);
            EventBus.$emit('expired-token');
            return null;
        }
        if (res.status === 500) {
            throw new Error(
                'We are experiencing a problem. We are looking into it',
            );
        }
        throw res.message || res.statusText;
    }

    const result = await res.json();
    if (result.metadata) {
        return {
            data: result.data,
        };
    }
    return result.data;
}

export default invoke;
