import getGlobalSocket, {
    getBaseEvents,
    removeSocket,
} from './socketManager';

import {
    queue as queueResponseHandler,
    clear as cleanResponseHandler,
} from './responseQueueManager';

import { EventBus } from '../..';

export function startSocketConnection(connectionStatusCallback = () => {}) {
    const SOCKET = getGlobalSocket();
    const BASE_EVENTS = getBaseEvents();

    SOCKET.on(BASE_EVENTS.CONNECT, () => {
        connectionStatusCallback({
            connected: true,
            connectionError: false,
            authError: false,
        });
    });

    SOCKET.on(BASE_EVENTS.RECONNECT, () => {
        SOCKET.removeAllListeners();
        connectionStatusCallback({
            connected: true,
            connectionError: false,
            authError: false,
        });
    });

    SOCKET.on(BASE_EVENTS.DISCONNECT, () => {
        connectionStatusCallback({
            connected: false,
            connectionError: false,
            authError: false,
        });
    });

    SOCKET.on(BASE_EVENTS.CONNECTION_ERROR, () => {
        connectionStatusCallback({
            connected: false,
            connectionError: true,
            authError: false,
        });
    });

    SOCKET.on(BASE_EVENTS.AUTHENTICATION_ERROR, () => {
        EventBus.$emit('expired-token');
        connectionStatusCallback({
            connected: false,
            connectionError: true,
            authError: true,
        });
    });

    SOCKET.connect();
    if (SOCKET.connected) {
        connectionStatusCallback({
            connected: true,
            connectionError: false,
            authError: false,
        });
    }
    if (SOCKET.disconnected) {
        connectionStatusCallback({
            connected: false,
            connectionError: true,
            authError: false,
        });
        SOCKET.connect();
    }
}

export function endSocketConnection(payload = {}) {
    const SOCKET = getGlobalSocket();
    const BASE_EVENTS = getBaseEvents();

    const generateEventId = () => `evt::global::${Date.now()}`;

    return new Promise((resolve, reject) => {
        const responseHandler = (res) => {
            if (!(res.status >= 200 && res.status < 300)) {
                const error = new Error(res.message);
                reject(error.data);
            }
            resolve(res.data);
        };
        const eventId = generateEventId();
        queueResponseHandler(eventId, responseHandler);
        SOCKET.emit(BASE_EVENTS.CONNECTION_END, { eventId, ...payload });
    }).finally(() => {
        removeSocket();
        cleanResponseHandler();
    });
}
