import {
    createCourse,
    modifyCourse,
    fetchCourse,
    fetchCourses,
    deleteCourse,
    uploadCourseFiles,
} from '../../../utils/api/http/courses/courses';
import { generateMutations } from '../../utils';

const defaultState = {
    courses: [],
    currentCourse: {},
    projectFiles: [],
    page: 0,
    limit: 6,
};

const getters = {
    courses: (state) => state.courses,
    files: (
        state,
        localGetters,
        rootState,
        rootGetters,
    ) => {
        const currentRuntime = rootGetters['runtimes/currentRuntime'];
        return currentRuntime
            ? state.projectFiles.filter((projectFile) => {
                const fileRuntime = projectFile.runtime.toLowerCase();
                const chosenRuntime = currentRuntime.toLowerCase();
                return fileRuntime === chosenRuntime;
            })
            : [];
    },
    filePaths: (state, localGetters) => localGetters
        .files.map((file) => file.name),
};

const actions = {
    async createCourse({ commit, state }, course) {
        const mutation = 'setCourses';
        const { courses } = state;
        const createdCourse = await createCourse(course);
        const newState = [createdCourse, ...courses];

        commit(mutation, newState);
        return createdCourse;
    },

    async modifyCourse({ commit, state }, { courseId, updates }) {
        const mutation = 'setCourses';
        const { courses } = state;
        const modifiedCourse = await modifyCourse(courseId, updates);
        const newState = courses.map((course) => (
            course.id === courseId
                ? modifiedCourse
                : course
        ));

        commit(mutation, newState);
        commit('setCurrentCourse', modifiedCourse);
        return modifiedCourse;
    },

    setPage({ commit }, newPage) {
        commit('setPage', newPage);
    },

    setCurrentCourse({ commit, state }, { courseId = '' }) {
        const { courses } = state;
        const foundCourse = courses.find((course) => course.id === courseId);
        commit('setCurrentCourse', foundCourse);
    },

    async fetchCourse({ commit, state }, courseId) {
        const mutation = 'setCourses';
        const { courses } = state;
        const foundCourse = courses.find((course) => course.id === courseId);
        if (foundCourse) return foundCourse;
        const result = await fetchCourse(courseId);
        const newState = [...courses, result];

        commit(mutation, newState);
        return result;
    },

    async fetchCourses({ commit, state }, { page = 0, force = false } = {}) {
        const mutation = 'setCourses';
        const { courses, limit } = state;
        if (page === 0 && courses.length > 0 && !force) {
            return courses;
        }
        const result = await fetchCourses({ page, limit });
        const newState = result;
        commit(mutation, newState);
        return result;
    },

    async deleteCourse({ commit, state }, courseId) {
        const mutation = 'setCourses';
        const { courses } = state;
        const result = await deleteCourse(courseId);
        const newState = courses.filter((course) => course.id !== result.id);
        commit(mutation, newState);
        return result;
    },

    async getCourseProjectFiles({ commit, state }, courseId) {
        const mutation = 'setProjectFiles';
        const { courses } = state;
        let foundCourse = courses.find((course) => course.id === courseId);
        if (!foundCourse) {
            foundCourse = await fetchCourse(courseId);
        }
        const files = foundCourse.projectFiles || [];
        commit(mutation, files);
    },

    async uploadCourseFiles({ commit, state }, { input, opts }) {
        const mutation = 'setCourses';
        const { courses } = state;
        const res = await uploadCourseFiles(input, opts);
        const newState = courses.map((course) => {
            let newCourse = course;
            if (course.id === opts.courseId) {
                newCourse = res;
            }
            return newCourse;
        });
        commit(mutation, newState);

        const files = res.projectFiles || [];
        commit('setProjectFiles', files);
        return res;
    },
};

const mutations = generateMutations(defaultState);

export default {
    state: defaultState,
    namespaced: true,
    getters,
    actions,
    mutations,
};
