import { generateMutations } from '../../utils';

const defaultState = {
    runtimes: ['node', 'java', 'php', 'python', 'pio', 'vscode'],
    currentRuntime: '',
};

const getters = {
    runtimes: (state) => state.runtimes,
    currentRuntime: (state) => state.currentRuntime,
};

const actions = {
    async setCurrentRuntime({ commit }, runtime) {
        commit('setCurrentRuntime', runtime);
    },

    async addRuntime({ commit, state }, runtime) {
        const newState = [...state.runtimes, runtime];
        commit('setRuntimes', newState);
    },
};

const mutations = generateMutations(defaultState);

export default {
    state: defaultState,
    namespaced: true,
    getters,
    mutations,
    actions,
};
