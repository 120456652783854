import {
    createLesson,
    modifyLesson,
    fetchLessons,
    fetchLesson,
    deleteLesson,
} from '../../../utils/api/http/lessons/lessons';
import { generateMutations } from '../../utils';

const defaultState = {
    lessons: [],
    tabbedLessons: [],
    activeLesson: null,
};

const getters = {
    lessons: (
        state,
        localGetters,
        rootState,
        rootGetters,
    ) => {
        const currentRuntime = rootGetters['runtimes/currentRuntime'];
        return currentRuntime
            ? state.lessons
                .filter((lesson) => {
                    const lessonRuntime = lesson.runtime.toLowerCase();
                    const chosenRuntime = currentRuntime.toLowerCase();
                    return lessonRuntime === chosenRuntime;
                })
            : [];
    },
    activeLesson: ({ activeLesson }) => activeLesson,
    tabbedLessons: (
        state,
        localGetters,
        rootState,
        rootGetters,
    ) => {
        const currentRuntime = rootGetters['runtimes/currentRuntime'];
        return state.tabbedLessons
            .filter((lesson) => lesson.runtime === currentRuntime);
    },
};

const actions = {
    async createLesson({ commit, state }, lesson) {
        const mutation = 'setLessons';
        const { lessons } = state;

        const createdLesson = await createLesson({
            ...lesson,
            order: lessons.length + 1,
        });

        const newState = [...lessons, createdLesson];

        commit(mutation, newState);
        return createdLesson;
    },

    async modifyLesson({ commit, state }, { lessonId, updates }) {
        const mutation = 'setLessons';
        const tabbedLessonsMutation = 'setTabbedLessons';
        const { lessons, tabbedLessons, activeLesson } = state;
        const modifiedLesson = await modifyLesson(lessonId, updates);
        const newState = lessons.map((lesson) => (
            lesson.id === lessonId
                ? modifiedLesson
                : lesson
        ));

        const tabbedLessonsState = tabbedLessons.map((lesson) => (
            lesson.id === lessonId
                ? modifiedLesson
                : lesson
        ));

        if (activeLesson.id === modifiedLesson.id) {
            const newActiveLesson = modifiedLesson;
            commit('setActiveLesson', newActiveLesson);
        }

        commit(mutation, newState);
        commit(tabbedLessonsMutation, tabbedLessonsState);
        return modifiedLesson;
    },

    async fetchLesson({ commit, state }, lessonId) {
        const mutation = 'setLessons';
        const { lessons } = state;

        const foundLesson = lessons.find((lesson) => lesson.id === lessonId);
        if (foundLesson) return foundLesson;

        const result = await fetchLesson(lessonId);
        const newState = [result, ...lessons];
        commit(mutation, newState);
        return result;
    },

    async fetchLessons({ commit }) {
        const mutation = 'setLessons';
        const result = await fetchLessons();
        const newState = result;
        commit(mutation, newState);
        return result;
    },

    async deleteLesson({ commit, state }, lessonId) {
        const mutation = 'setLessons';
        const { lessons } = state;
        const result = await deleteLesson(lessonId);
        const newState = lessons.filter((lesson) => lesson.id !== lessonId);
        commit(mutation, newState);
        return result;
    },

    async changeLessonsOrder({ commit }, { lessons }) {
        const mutation = 'setLessons';
        const promises = lessons
            .map(async (lesson, index) => {
                const newLesson = { ...lesson, order: index + 1 };
                await modifyLesson(lesson.id, newLesson);
            });

        await Promise.resolve(promises);
        const newState = lessons
            .map((lesson, index) => ({ ...lesson, order: index + 1 }));
        commit(mutation, newState);
    },

    setActiveLesson({ commit, state }, lessonId) {
        const mutation = 'setActiveLesson';
        const { lessons } = state;
        const activeLesson = lessons.find((lesson) => lesson.id === lessonId);
        commit(mutation, activeLesson);
    },

    unSetActiveLesson({ commit }) {
        const mutation = 'setActiveLesson';
        commit(mutation, null);
    },

    setTabbedLesson({ commit, state }, lessonId) {
        const mutation = 'setTabbedLessons';
        const { tabbedLessons, lessons } = state;

        const lessonExists = tabbedLessons.find(
            (lesson) => lesson.id === lessonId,
        );

        if (lessonExists) return;
        const currentLesson = lessons.find(
            (lesson) => lesson.id === lessonId,
        );
        const newState = [...tabbedLessons, currentLesson];
        commit(mutation, newState);
    },

    unSetTabbedLesson({ commit, state }, lessonId) {
        const mutation = 'setTabbedLessons';
        const { tabbedLessons } = state;

        const newState = tabbedLessons.filter((lesson) => lesson.id !== lessonId);
        commit(mutation, newState);
    },

    unSetTabbedLessons({ commit }) {
        const mutation = 'setTabbedLessons';
        const newState = [];
        commit(mutation, newState);
    },

    updateActiveLessonContent: {
        root: true,
        handler: ({ commit, state }, { lessonId, content }) => {
            const mutation = 'updateActiveLessonContent';
            if (state.activeLesson.id === lessonId) {
                commit(mutation, content);
            }
        },
    },
};

const mutations = {
    ...generateMutations(defaultState),
    updateActiveLessonContent: (state, content) => {
        state.activeLesson.content[0].content = content;
    },
};

export default {
    state: defaultState,
    namespaced: true,
    getters,
    actions,
    mutations,
};
