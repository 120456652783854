<template>
    <div class="wrapper">
        <div class="content-wrapper">
            <section class="content__header">
                <figure>
                    <img src="@/assets/img/logo.png" class="h-40">
                </figure>
            </section>
            <section class="content">
                <div class="form__actions">
                    <button class="button right"
                            type="button"
                            @click="signInWithGoogle"
                    >
                        <font-awesome-icon icon="sign-in-alt" class="mr-3" />
                        Sign in with Google
                    </button>
                </div>
                <section class="line">
                    <div class="line__left" />
                    <p class="line__center">
                        OR
                    </p>
                    <div class="line__right" />
                </section>
                <form class="form" @submit="signIn">
                    <div class="form__control">
                        <label class="form__label">Email</label>
                        <input v-model.trim="email"
                               class="form__input"
                               type="email"
                               placeholder="Example: name@mail.com"
                        >
                    </div>
                    <div class="form__control">
                        <label class="form__label">Password</label>
                        <input v-model.trim="password"
                               class="form__input"
                               type="password"
                               placeholder="Your password"
                        >
                    </div>
                    <div class="form__actions">
                        <button class="button left" type="submit">
                            <font-awesome-icon icon="sign-in-alt"
                                               class="mr-3"
                            />
                            Sign in
                        </button>
                    </div>
                </form>
            </section>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },

    methods: {
        ...mapActions('auth', {
            signInImpl: 'signIn',
            signInWithGoogleImpl: 'signInWithGoogle',
        }),

        async signIn(e) {
            e.preventDefault();
            const payload = {
                email: this.email,
                password: this.password,
            };
            const user = await this.signInImpl(payload);
            if (user) this.$router.push({ path: '/home' });
        },

        async signInWithGoogle() {
            await this.signInWithGoogleImpl();
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    @apply h-screen;
    @apply w-screen;
    @apply grid;
    @apply place-items-center;
}

.content-wrapper {
    width: 70vw;
    height: 70vh;
    @apply grid;
    @apply place-items-center;
    grid-template-columns: 1fr;
    grid-template-rows: 15rem 1fr;
    grid-template-areas:
        "header"
        "content";
}

.content__header {
    grid-area: header;
    @apply grid;
    @apply place-items-center;
}

.content{
    grid-area: content;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    width: 50%;
}

.button {
    @apply flex-1;
    @apply rounded-md;
    @apply shadow-sm;
    @apply h-12;
    @apply bg-white;
    @apply text-gray-700;
    @apply text-base;
    @apply font-medium;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply mx-2;
    &.left {
        @apply border;
        @apply border-solid;
        @apply border-gray-300;
        @apply bg-gray-100;
        &:hover,&:focus {
            @apply text-gray-900;
        }
    }
    &.right {
       @apply bg-accent;
       @apply text-white;
    }
    &:hover{
        @apply outline-none;
    }
}

.line {
    @apply my-4;
    @apply mx-0;
    @apply w-full;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    &__left {
        height: 1px;
        width: 48%;
        @apply bg-gray-300;
    }
    &__center {
        @apply h-10;
        @apply w-10;
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply font-medium;
        @apply text-gray-700;
        @apply bg-white;
    }
    &__right {
        height: 1px;
        width: 48%;
        @apply bg-gray-300;
    }
}
</style>
