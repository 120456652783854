import {
    createCourseLesson,
    modifyCourseLesson,
    fetchCourseLesson,
    fetchCourseLessons,
    deleteCourseLesson,
} from '../../../../utils/api/http/courses/lessons/lessons';
import { generateMutations } from '../../../utils';

const defaultState = {
    courseLessons: [],
    tabbedCourseLessons: [],
    activeCourseLesson: null,
};

const getters = {
    courseLessons: (
        state,
        localGetters,
        rootState,
        rootGetters,
    ) => {
        const currentRuntime = rootGetters['runtimes/currentRuntime'];
        const lessons = currentRuntime
            ? state.courseLessons.filter((lesson) => {
                const lessonRuntime = lesson.runtime.toLowerCase();
                const chosenRuntime = currentRuntime.toLowerCase();
                return lessonRuntime === chosenRuntime;
            }) : [];
        return lessons;
    },
    activeCourseLesson: ({ activeCourseLesson }) => activeCourseLesson,
    tabbedCourseLessons: (
        state,
        localGetters,
        rootState,
        rootGetters,
    ) => {
        const currentRuntime = rootGetters['runtimes/currentRuntime'];
        return state.tabbedCourseLessons
            .filter((lesson) => lesson.runtime === currentRuntime);
    },
};

const actions = {
    async createCourseLesson(context, { courseId, lesson }) {
        const { dispatch, commit, state } = context;
        const mutation = 'setCourseLessons';
        const { courseLessons } = state;
        const createdCourseLesson = await createCourseLesson(
            courseId,
            { ...lesson, order: courseLessons.length + 1 },
        );

        const newState = [...courseLessons, createdCourseLesson];
        await dispatch('setActiveCourseLesson', createdCourseLesson.id);
        commit(mutation, newState);
        return createdCourseLesson;
    },

    async modifyCourseLesson(context, { courseId, lessonId, updates }) {
        const { commit, state } = context;
        const mutation = 'setCourseLessons';
        const tabbedLessonsMutation = 'setTabbedCourseLessons';
        const {
            courseLessons,
            tabbedCourseLessons,
            activeCourseLesson,
        } = state;

        const modifiedCourseLesson = await modifyCourseLesson({
            courseId,
            lessonId,
            updates,
        });

        const newState = courseLessons.map((courseLesson) => (
            courseLesson.id === lessonId
                ? modifiedCourseLesson
                : courseLesson
        ));

        const tabbedLessonsState = tabbedCourseLessons.map((courseLesson) => (
            courseLesson.id === lessonId
                ? modifiedCourseLesson
                : courseLesson
        ));

        if (activeCourseLesson.id === modifiedCourseLesson.id) {
            const activeLesson = modifiedCourseLesson;
            commit('setActiveCourseLesson', activeLesson);
        }

        commit(mutation, newState);
        commit(tabbedLessonsMutation, tabbedLessonsState);
        return modifiedCourseLesson;
    },

    async fetchCourseLesson(context, { courseId, lessonId }) {
        const { commit, state } = context;
        const mutation = 'setCourseLessons';
        const { courseLessons } = state;

        const foundCourseLesson = courseLessons
            .find((courseLesson) => courseLesson.id === lessonId);
        if (foundCourseLesson) return foundCourseLesson;

        const result = await fetchCourseLesson(courseId, lessonId);
        const newState = [result, ...courseLessons];
        commit(mutation, newState);
        return result;
    },

    async fetchCourseLessons({ commit }, courseId) {
        const mutation = 'setCourseLessons';
        const result = await fetchCourseLessons(courseId);
        const newState = result;

        commit(mutation, newState);
        return result;
    },

    async deleteCourseLesson({ commit, state }, { courseId, lessonId }) {
        const mutation = 'setCourseLessons';
        const { courseLessons } = state;
        const result = await deleteCourseLesson(courseId, lessonId);
        const newState = [
            ...courseLessons
                .filter((courseLesson) => courseLesson.id !== lessonId),
        ];
        commit(mutation, newState);
        return result;
    },

    async changeCourseLessonsOrder({ commit }, { lessons, courseId }) {
        const mutation = 'setCourseLessons';
        const promises = lessons
            .map(async (lesson, index) => {
                const newLesson = { ...lesson, order: index + 1 };
                await modifyCourseLesson({ courseId, lessonId: lesson.id, updates: newLesson });
            });
        await Promise.resolve(promises);
        const newState = lessons
            .map((lesson, index) => ({ ...lesson, order: index + 1 }));
        commit(mutation, newState);
    },

    setCourseLessons({ commit }, { lessons }) {
        const mutation = 'setCourseLessons';
        const newState = lessons;
        commit(mutation, newState);
    },

    setActiveCourseLesson({ commit, getters: localGetters }, lessonId) {
        const mutation = 'setActiveCourseLesson';
        const { courseLessons } = localGetters;
        const activeLesson = courseLessons.find((lesson) => lesson.id === lessonId);
        commit(mutation, activeLesson);
    },

    unSetActiveCourseLesson({ commit }) {
        const mutation = 'setActiveCourseLesson';
        commit(mutation, null);
    },

    setTabbedCourseLesson({ commit, getters: localGetters }, lessonId) {
        const mutation = 'setTabbedCourseLessons';
        const {
            tabbedCourseLessons,
            courseLessons,
        } = localGetters;
        const lessonExists = tabbedCourseLessons.find(
            (lesson) => lesson.id === lessonId,
        );

        if (lessonExists) return;

        const currentLesson = courseLessons.find(
            (lesson) => lesson.id === lessonId,
        );
        if (!currentLesson) return;
        const newState = [...tabbedCourseLessons, currentLesson];
        commit(mutation, newState);
    },

    unSetTabbedCourseLesson({ commit, state }, lessonId) {
        const mutation = 'setTabbedCourseLessons';
        const {
            tabbedCourseLessons,
        } = state;
        const newState = [
            ...tabbedCourseLessons.filter(
                (courseLesson) => courseLesson.id !== lessonId,
            ),
        ];
        commit(mutation, newState);
    },

    unSetTabbedCourseLessons({ commit }) {
        const mutation = 'setTabbedCourseLessons';
        const newState = [];
        commit(mutation, newState);
    },

    updateActiveCourseLessonContent: {
        root: true,
        handler: ({ commit, state }, { lessonId, content }) => {
            const mutation = 'updateActiveCourseLessonContent';

            if (state.activeCourseLesson
                  && state.activeCourseLesson.id === lessonId) {
                commit(mutation, content);
            }
        },
    },
};

const mutations = {
    ...generateMutations(defaultState),
    updateActiveCourseLessonContent: (state, content) => {
        state.activeCourseLesson.content[0].content = content;
    },
};

export default {
    state: defaultState,
    namespaced: true,
    getters,
    actions,
    mutations,
};
