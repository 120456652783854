import { submitContent } from '../../../utils/api/socket/content/content';

const actions = {
    async submitContent({ dispatch }, payload) {
        const {
            courseId = null,
            lessonId = null,
            contentId = null,
            content = '',
        } = payload;

        const res = await submitContent({
            courseId,
            lessonId,
            contentId,
            content,
        });

        const action = res.courseId
            ? 'updateActiveCourseLessonContent'
            : 'updateActiveLessonContent';

        dispatch(action, res, { root: true });
    },

};

export default {
    namespaced: true,
    actions,
};
