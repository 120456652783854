import { v4 as uuid } from 'uuid';
import getSocket, { getBaseEvents } from '../socketManager';

import {
    queue as queueResponseHandler,
    get as getResponseHandler,
    clear as clearResponseHandler,
} from '../responseQueueManager';

const CONTENT_EVENTS = {
    SUBMIT_CONTENT: 'submit::content',
    ...getBaseEvents(),
};

const generateEventId = () => `evt::content::${uuid()}`;

export function submitContent(payload) {
    const SOCKET = getSocket();
    SOCKET.on(CONTENT_EVENTS.RESPONSE, getResponseHandler);

    return new Promise((resolve, reject) => {
        const eventId = generateEventId();
        const data = { eventId, ...payload };
        const responseHandler = (res) => {
            if (!(res.status >= 200 && res.status < 300)) {
                const error = new Error(res.message);
                reject(error.data);
            }
            resolve(res.data);
        };
        queueResponseHandler(eventId, responseHandler);
        SOCKET.emit(CONTENT_EVENTS.SUBMIT_CONTENT, data);
    });
}

export function removeContentSocket() {
    const SOCKET = getSocket();
    SOCKET.off(CONTENT_EVENTS.SUBMIT_CONTENT);
    clearResponseHandler({
        prefix: 'content',
    });
}
