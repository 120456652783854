import {
    startSocketConnection,
    endSocketConnection,
} from '../../../utils/api/socket';

import {
    generateMutations,
} from '../../utils';

const defaultState = {
    connectionStatus: {
        connected: false,
        connectionError: false,
        authError: false,
    },
};

const getters = {
    socketConnectionState: (state) => state.connectionStatus,
};

const mutations = generateMutations(defaultState);

const actions = {
    startSocketConnection({ commit }) {
        const setConnectionStatus = (connectionStatus) => {
            commit('setConnectionStatus', connectionStatus);
        };
        startSocketConnection(setConnectionStatus);
    },

    async endSocketConnection({ commit }) {
        await endSocketConnection();
        const connectionStatus = {
            connected: false,
            connectionError: false,
            authError: false,
        };
        commit('setConnectionStatus', connectionStatus);
    },
};

export default {
    namespaced: true,
    state: defaultState,
    getters,
    actions,
    mutations,
};
