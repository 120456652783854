import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faFile,
} from '@fortawesome/free-regular-svg-icons';
import {
    faFolderPlus,
    faAngleRight,
    faAngleDown,
    faGripVertical,
    faArrowRight,
    faAngleUp,
    faCode,
    faTrash,
    faCheck,
    faPencilAlt,
    faSave,
    faTimes,
    faPlus,
    faBook,
    faEllipsisV,
    faFileAlt,
    faSignOutAlt,
    faSignInAlt,
    faArrowLeft,
    faSync,
} from '@fortawesome/free-solid-svg-icons';

import App from '@/App.vue';
import router from './router';
import store from './store/store';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import './assets/scss/styles.scss';
import './assets/tailwind.css';

library.add(
    faFileAlt,
    faSignOutAlt,
    faFolderPlus,
    faArrowRight,
    faAngleRight,
    faGripVertical,
    faPencilAlt,
    faSignInAlt,
    faCheck,
    faEllipsisV,
    faBook,
    faTrash,
    faPlus,
    faArrowLeft,
    faAngleUp,
    faAngleDown,
    faSave,
    faCode,
    faTimes,
    faFile,
    faSave,
    faSync,
);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
