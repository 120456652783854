import Vue from 'vue';

export const EventBus = new Vue();

export function decodeToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map((char) => `%${(`00${char.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join(''));

    return JSON.parse(jsonPayload);
}

export function validateEmptyFields(objc = {}) {
    const keys = Object.keys(objc);
    const values = Object.values(objc);

    const empty = values
        .map((value, index) => (!value ? keys[index] : null))
        .filter((value) => value !== null)
        .map((value) => value.toLowerCase());
    if (empty.length > 0) throw new Error(`${empty} are required`);
    return empty;
}
